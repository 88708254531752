import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";
import { loadingEnd, loadingStart } from "../setLoading/setLoading";
import ToastMessage from "../../component/toast/ToastMessage";

export const authSendOtp = createAsyncThunk(
  "authSession/post",
  async (data, { rejectWithValue, dispatch }) => {
    // dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const backend = new API();
      const response = await backend.fetch(API.clientSendOtp(), {
        method: "POST",
        body: formData,
      });
      // dispatch(loadingEnd());
      ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      // dispatch(loadingEnd());
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const contactUsApi = createAsyncThunk(
  "contactUsApi/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const backend = new API();
      const response = await backend.fetch(API.contactUsApi(), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error send otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const authVerifyOtp = createAsyncThunk(
  "authVerifyOtp/post",
  async (data, { rejectWithValue, dispatch }) => {
    // dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const backend = new API();
      const response = await backend.fetch(API.verifyOtpClient(), {
        method: "POST",
        body: formData,
      });
      // if (response.status === "False") {
      //   let logiUserDetilas = {
      //     device_type: "android ",
      //     phonecode: data?.phonecode,
      //     mobile: data?.mobile,
      //     register_as: "Business",
      //     login_as: "Owner",
      //   };
      //   dispatch(authLoginUser(logiUserDetilas));
      if (response.status === false) {
        // ToastMessage(response);
      } else if (response.status === true) {
        localStorage.setItem("Customertoken", response?.data?.login_token);
        // dispatch(getClientDetails(response?.data?.login_token));
        // // If login is successful, fetch the profile status
        // const profileStatusResponse = await backend.fetch(
        //   API.getProfileStatus(),
        //   {
        //     headers: {
        //       Accept: "application/json",
        //       Authorization: `Bearer ${response.login_token}`,
        //     },
        //   }
        // );

        // if (profileStatusResponse.status === true) {
        //   // Update local storage with profile status information
        //   localStorage.setItem(
        //     "seller_name",
        //     profileStatusResponse?.data?.seller_name
        //   );
        //   localStorage.setItem(
        //     "business_logo",
        //     profileStatusResponse?.data?.business_logo
        //   );
        //   localStorage.setItem(
        //     "business_name",
        //     profileStatusResponse?.data?.business_name
        //   );
        // }
        // localStorage.setItem("business_logo", response?.data?.business_logo);
        // localStorage.setItem("name", response?.data?.name);
        // localStorage.setItem("business_name", response?.data?.business_name);
      }
      // dispatch(loadingEnd());
      ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      // dispatch(loadingEnd());
      console.error("Error otp verify:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const getClientDetails = createAsyncThunk(
  "getClientDetails/get",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const backend = new API();
      // console.log(data);
      const response = await backend.fetch(API.getClientDetails(), {
        method: "GET",
        // Authorization: `Bearer  ${localStorage.getItem("Customertoken")}`
        Authorization: `Bearer ${data}`,
      });
      // localStorage.setItem("token", response?.login_token);
      dispatch(loadingEnd());
      // ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error login user:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);
export const addReferralSignup = createAsyncThunk(
  "addReferralSignup/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const backend = new API();
      const response = await backend.fetch(API.assignClientLoyalty(), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());
      ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error siging up:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);


export const authLoginUser = createAsyncThunk(
  "authLoginUser/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const backend = new API();
      const response = await backend.fetch(API.loginUser(), {
        method: "POST",
        body: formData,
      });
      localStorage.setItem("token", response?.login_token);
      dispatch(loadingEnd());
      ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error login user:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const authResendOtp = createAsyncThunk(
  "authResendOtp/post",
  async (data, { rejectWithValue, dispatch }) => {
    // dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const backend = new API();
      const response = await backend.fetch(API.clientSendOtp(), {
        method: "POST",
        body: formData,
      });
      // dispatch(loadingEnd());
      ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      // dispatch(loadingEnd());
      console.error("Error resend otp:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);
