import React, { useState, useEffect } from "react";
import "../../assets/style/daktheme.scss";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Image,
  InputGroup,
  Row,
  Stack,
  Tab,
  Tabs,
  Form,
  Offcanvas,
} from "react-bootstrap";
import OTPInput from "react-otp-input";
import "../../assets/style/daktheme.scss";
import { HairSalonLogo, TapgproFullLogo } from "../../assets/images";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  authResendOtp,
  authSendOtp,
  authVerifyOtp,
  getClientDetails,
} from "../../redux/authSession";
import PhoneInput from "react-phone-input-2";
import { isObjectEmpty } from "../../utils/commonFunctions";
import { clearVerifyOtp } from "../../redux/authSession/authSession";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";

const CustomerLoginX = ({ seller_id, setCurrentScreen }) => {
  const [sendOtpData, setSendOtpData] = useState({
    phonecode: "91",
    mobile: "",
    user_type: "",
    seller_id: seller_id,
    action_type: "login",
  });
  const [verifyOtpData, setVerifyOtpData] = useState({
    phonecode: "91",
    mobile: "",
    enter_otp: "",
    seller_id: seller_id,
  });
  const [otpScreen, setOtpScreen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listMiniWebsite = useSelector(
    (state) => state?.miniWebsite?.listMiniWebsite
  );

  const verifyOtpResponce = useSelector(
    (state) => state?.authSession?.verifyOtp
  );
  const sendOtpResponse = useSelector((state) => state?.authSession?.sendOtp);

  // useEffect(() => {
  //   if (sendOtpResponse?.status === true) {
  //     setOtpScreen(true);
  //   }
  // }, [sendOtpResponse]);
  useEffect(() => {
    if (sendOtpResponse?.status === true) {
      // Switch to OTP screen
      setOtpScreen(true);

      // Preserve mobile number in verifyOtpData
      setVerifyOtpData((prevData) => ({
        ...prevData,
        mobile: sendOtpData.mobile,
      }));
    }
  }, [sendOtpResponse, sendOtpData.mobile]);

  // useEffect(() => {
  //   const isAuthenticated = localStorage.getItem("Customertoken");
  //   if (isAuthenticated) {
  //     // navigate("/");
  //     setCurrentScreen("customerProfile");
  //   } else {
  //     // navigate("/login");
  //   }
  // }, [verifyOtpResponce]);
  const { id } = useParams();
  // useEffect(() => {
  //   if (verifyOtpResponce && !isObjectEmpty(verifyOtpResponce)) {
  //     if (verifyOtpResponce?.data?.login_token) {
  //       dispatch(getClientDetails(verifyOtpResponce?.data?.login_token));
  //       localStorage.setItem("sellerId", id)
  //       if (verifyOtpResponce?.data?.isClientExists) {
  //         // console.log("true");
  //         setCurrentScreen("customerProfile");
  //       } else {
  //         // console.log("false");

  //         setCurrentScreen("addReferral");
  //       }
  //     }
  //   } else {
  //     // setIsLoggedIn(false);
  //   }
  // }, [verifyOtpResponce]);

  useEffect(() => {
    if (verifyOtpResponce && !isObjectEmpty(verifyOtpResponce)) {
      if (verifyOtpResponce?.data?.login_token) {
        dispatch(getClientDetails(verifyOtpResponce?.data?.login_token));
        localStorage.setItem("sellerId", id);

        if (verifyOtpResponce?.data?.isClientExists) {
          setCurrentScreen("customerProfile");
        } else {
          setCurrentScreen("addReferral");
        }
      }
    }
  }, [verifyOtpResponce]);

  useEffect(() => {
    if (!localStorage.getItem("Customertoken")) {
      setOtpScreen(false);
    }
  }, []);

  const handlePhoneChangeX = (value) => {
    // Remove country code from the phone number
    const indexToRemove = value.indexOf("91");
    const resultString =
      value.substring(0, indexToRemove) + value.substring(indexToRemove + 2);

    setSendOtpData((prevData) => ({
      ...prevData,
      mobile: resultString,
      inputValue: value,
    }));
    setVerifyOtpData((prevData) => ({
      ...prevData,
      mobile: resultString,
      inputValue: value,
    }));
  };
  const handlePhoneChange = (value) => {
    // Extract country code and phone number
    const indexToRemove = value.indexOf("91");
    const resultString =
      value.substring(0, indexToRemove) + value.substring(indexToRemove + 2);

    // console.log("Phone changed to:", resultString);

    setSendOtpData((prevData) => ({
      ...prevData,
      mobile: resultString,
      inputValue: value,
    }));

    setVerifyOtpData((prevData) => ({
      ...prevData,
      mobile: resultString,
      inputValue: value,
    }));

    // console.log("verifyOtpData after phone change:", verifyOtpData);
  };

  // const handleOtpChange = (value) => {
  //   setVerifyOtpData((prevData) => ({
  //     ...prevData,
  //     enter_otp: value,
  //   }));
  // };
  const handleOtpChange = (value) => {
    // console.log("OTP entered:", value);

    setVerifyOtpData((prevData) => ({
      ...prevData,
      enter_otp: value,
    }));

    // console.log("verifyOtpData after OTP change:", verifyOtpData);
  };
  const handleOtpSendX = (e) => {
    e.preventDefault();
    dispatch(authSendOtp(sendOtpData));
  };
  const handleOtpSend = (e) => {
    e.preventDefault();

    // console.log("Sending OTP with mobile:", sendOtpData.mobile);

    // Dispatch OTP action
    dispatch(authSendOtp(sendOtpData));

    // Explicitly set verifyOtpData.mobile
    setVerifyOtpData((prevData) => ({
      ...prevData,
      mobile: sendOtpData.mobile,
    }));
  };

  // const handleOtpVerifySubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(authVerifyOtp(verifyOtpData));
  // };

  const handleOtpVerifySubmit = (e) => {
    e.preventDefault();

    // console.log("Verifying OTP with data:", verifyOtpData);

    if (verifyOtpData?.mobile && verifyOtpData?.enter_otp) {
      dispatch(authVerifyOtp(verifyOtpData));
    } else {
      console.error("Mobile number or OTP is missing");
    }
  };

  const handleOtpResendOtp = () => {
    let resendOtp = {
      phonecode: verifyOtpData?.phonecode,
      mobile: verifyOtpData?.mobile,
      action_type: "resend_otp",
      seller_id: seller_id,
    };
    dispatch(authResendOtp(resendOtp));
  };
  return (
    <div>
      <div className="main-background-color background-svg">
        <Container>
          <div className="min-h-screen vstack">
            <div className="text-center mt-20 mb-16">
              <div className="avatar w-20 h-20 h-md-32 w-md-32 mb-4">
                <div className=" w-20 h-20 h-md-32 w-md-32 rounded-circle border hstack justify-content-center">
                  <Image
                    className="w-20 h-20 h-md-24 w-md-24"
                    src={
                      listMiniWebsite?.data?.profileHeader?.business_logo ||
                      HairSalonLogo
                    }
                    alt="image not found"
                  />
                </div>
              </div>

              <h3 className="text-light mb-6">
                {listMiniWebsite?.data?.profileHeader?.businessName ||
                  "Salon name"}
              </h3>
              <p>
                {!otpScreen
                  ? "Get started & grab best deals from us"
                  : `Enter OTP recieved on ${sendOtpData.inputValue}`}
              </p>
            </div>
            {!otpScreen ? (
              <div className="w-md-96 w-full mx-auto">
                <Form>
                  <Form.Group className="mb-6" controlId="formBasicEmail">
                    <Form.Label htmlFor="inputPassword5" className="text-light">
                      Mobile number
                    </Form.Label>
                    <PhoneInput
                      value={sendOtpData.inputValue}
                      country="in"
                      onChange={handlePhoneChange}
                      // inputClass="mt-4"
                      inputClass="control"
                      specialLabel="Enter phone number"
                      dropdownClass="shadow-2 rounded-2"
                      placeholder="enter phone number"
                      autocompleteSearch
                      enableSearch
                      countryCodeEditable={false}
                    />
                    {/* <Form.Control
                      className="form-control-dark border-0"
                      type="text"
                      id="inputtext"
                      aria-describedby="passwordHelpBlock"
                    /> */}
                  </Form.Group>

                  <Button
                    variant=""
                    className="bg-Golden-gradient text-dark w-full"
                    onClick={handleOtpSend}
                  >
                    Get OTP
                  </Button>
                </Form>
              </div>
            ) : (
              <div className="w-md-96 w-full mx-auto">
                <Form>
                  <OTPInput
                    containerStyle="d-flex gap-3 mb-12 justify-content-center"
                    inputStyle="form-control form-control-dark form-control-lg text-40 font-bold w-12 px-4 otp-input control"
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                    value={verifyOtpData?.enter_otp}
                    onChange={(value) => handleOtpChange(value)}
                    shouldAutoFocus
                    inputType="number"
                  />
                  <Button
                    variant=""
                    className="bg-Golden-gradient text-dark w-full"
                    onClick={handleOtpVerifySubmit}
                    disabled={verifyOtpData?.enter_otp.length < 6}
                  >
                    Verify OTP
                  </Button>
                  <p className="pt-8 text-center">
                    Didn’t receive OTP?{" "}
                    <Link
                      className="text-secondary font-bold ms-1 text-gradient"
                      onClick={handleOtpResendOtp}
                    >
                      Resend
                    </Link>
                  </p>
                </Form>
              </div>
            )}

            <div className="hstack justify-content-center mt-auto mb-20">
              <p>Powered by</p>
              <Image src={TapgproFullLogo} width={62} />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

const CustomerLogin = ({ seller_id, setCurrentScreen }) => {
  const [otpScreen, setOtpScreen] = useState(false);
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const [authData, setAuthData] = useState({
    phonecode: "91",
    mobile: localStorage.getItem("mobile") || "", // Load from localStorage if exists
    email: localStorage.getItem("email") || "", // Load from localStorage if exists
    inputValue: "91" + localStorage.getItem("mobile") || "", // Load from localStorage if exists
    enter_otp: "",
    seller_id: seller_id,
    action_type: "login",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listMiniWebsite = useSelector(
    (state) => state?.miniWebsite?.listMiniWebsite
  );

  const verifyOtpResponce = useSelector(
    (state) => state?.authSession?.verifyOtp
  );
  const sendOtpResponse = useSelector((state) => state?.authSession?.sendOtp);

  // console.log("Current authData state:", authData);

  useEffect(() => {
    // console.log("Send OTP response:", sendOtpResponse);

    if (sendOtpResponse?.status === true) {
      setOtpScreen(true);
    }
  }, [sendOtpResponse]);

  useEffect(() => {
    // console.log({verifyOtpResponce});

    if (verifyOtpResponce && !isObjectEmpty(verifyOtpResponce)) {
      if (verifyOtpResponce?.data?.login_token) {
        dispatch(getClientDetails(verifyOtpResponce?.data?.login_token));
        localStorage.setItem("sellerId", seller_id);

        if (verifyOtpResponce?.data?.isClientExists) {
          setCurrentScreen("customerProfile");
        } else {
          setCurrentScreen("addReferral");
        }
        dispatch(clearVerifyOtp());
      }
    }
  }, [verifyOtpResponce]);

  useEffect(() => {
    if (!localStorage.getItem("Customertoken")) {
      setOtpScreen(false);
    }
  }, []);

  const handlePhoneChange = (value) => {
    const indexToRemove = value.indexOf("91");
    const resultString =
      value.substring(0, indexToRemove) + value.substring(indexToRemove + 2);

    // console.log("Phone number entered:", resultString);

    setAuthData((prevData) => ({
      ...prevData,
      mobile: resultString,
      inputValue: value,
    }));

    // Save the mobile number in localStorage to persist it across re-renders
    localStorage.setItem("mobile", resultString);
  };

  const handleOtpChange = (value) => {
    // console.log("OTP entered:", value);

    setAuthData((prevData) => ({
      ...prevData,
      enter_otp: value,
    }));
  };

  const handleOtpSend = (e) => {
    e.preventDefault();
    // console.log("Sending OTP with authData:", authData);
    if (isEmailLogin) {
      const formData = { ...authData, mobile: "" };
      dispatch(authSendOtp(formData));
    } else {
      const formData = { ...authData, email: "" };
      dispatch(authSendOtp(formData));
    }
    // dispatch(authSendOtp(authData));
  };

  // const handleOtpVerifySubmit = (e) => {
  //   e.preventDefault();

  //   if (authData?.mobile && authData?.enter_otp) {
  //     // console.log("Verifying OTP with authData:", authData);
  //     dispatch(authVerifyOtp(authData));
  //   } else {
  //     console.error("Mobile number or OTP is missing");
  //   }
  // };
  const handleOtpVerifySubmit = (e) => {
    e.preventDefault();

    if ((authData?.mobile || authData?.email) && authData?.enter_otp) {
      if (isEmailLogin) {
        const formData = { ...authData, mobile: "" };
        console.log({formData});
        
        dispatch(authVerifyOtp(formData));
      } else {
        const formData = { ...authData, email: "" };
        console.log({formData});
        dispatch(authVerifyOtp(formData));
      }
      // dispatch(authVerifyOtp(authData));
    } else {
      console.error("Mobile number/email or OTP is missing");
    }
  };

  const handleOtpResendOtp = () => {
    let resendOtp = {
      phonecode: authData?.phonecode,
      mobile: authData?.mobile,
      email: authData?.email,
      action_type: "resend_otp",
      seller_id: seller_id,
    };
    // console.log("Resending OTP with:", resendOtp);
    dispatch(authResendOtp(resendOtp));
  };
  // {console.log(authData);
  //  }

  return (
    <div>
      <div className="main-background-color background-svg">
        <Container>
          <div className="min-h-screen vstack">
            <div className="text-center mt-20 mb-16">
              <div className="avatar w-20 h-20 h-md-32 w-md-32 mb-4">
                <div className=" w-20 h-20 h-md-32 w-md-32 rounded-circle border hstack justify-content-center">
                  <Image
                    className="w-20 h-20 h-md-24 w-md-24"
                    src={
                      listMiniWebsite?.data?.profileHeader?.business_logo ||
                      HairSalonLogo
                    }
                    alt="image not found"
                  />
                </div>
              </div>

              <h3 className="text-light mb-6">
                {listMiniWebsite?.data?.profileHeader?.businessName ||
                  "Salon name"}
              </h3>
              {/* <p className="text-light mb-6">
                {listMiniWebsite?.data?.profileHeader?.businessName || "Salon name"}
              </p> */}
              <p>
                {/* {!otpScreen
                  ? "Get started & grab best deals from us"
                  : `Enter OTP recieved on ${authData.inputValue}`} */}
                {!otpScreen
                  ? "Get started & grab best deals from us"
                  : `Enter OTP recieved on ${
                      localStorage.getItem("mobile") || "your mobile number"
                    }`}
              </p>
            </div>

            {!otpScreen ? (
              <div className="w-md-96 w-full mx-auto">
                <Form>
                  {!isEmailLogin ? (
                    <Form.Group className="mb-6" controlId="formBasicEmail">
                      <Form.Label
                        htmlFor="inputPassword5"
                        className="text-light"
                      >
                        Mobile number
                      </Form.Label>
                      <PhoneInput
                        value={authData.inputValue}
                        country="in"
                        onChange={handlePhoneChange}
                        inputClass="control"
                        specialLabel="Enter phone number"
                        dropdownClass="shadow-2 rounded-2"
                        placeholder="enter phone number"
                        autocompleteSearch
                        enableSearch
                        countryCodeEditable={false}
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group className="mb-6" controlId="formBasicEmail">
                      <Form.Label
                        htmlFor="inputPassword5"
                        className="text-light"
                      >
                        Email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        // variant="primary"
                        className="shadow-2 form-control-dark"
                        value={authData.email}
                        placeholder="Enter email"
                        onChange={(e) => {
                          
                          setAuthData({
                            ...authData,
                            email: e.target.value,
                          });
                          localStorage.setItem("email", e.target.value);
                        }}
                        required
                      />
                    </Form.Group>
                  )}

                  <Button
                    variant=""
                    className="bg-Golden-gradient text-dark w-full"
                    onClick={handleOtpSend}
                  >
                    Get OTP
                  </Button>
                </Form>

                {/* ////////////////// */}
                <p className="text-center my-4 text-light">or</p>
                <Stack
                  direction="horizontal"
                  className="mx-auto px-4 py-3 follow-up-class rounded cursor-pointer bg-dark"
                  onClick={() => setIsEmailLogin(!isEmailLogin)}
                >
                  {isEmailLogin ? (
                    <>
                      <div className="ms-auto me-3">
                        {/* <FiPhoneCall className="text-primary " size={24}/> */}
                        <FiPhoneCall className="text-muted " size={24} />
                      </div>
                      <div className="me-auto">
                        <p
                          className="text-sm text-light fw-bold"
                          style={{ fontWeight: "500" }}
                        >
                          Login with Phone
                        </p>
                        <p className="text-xs text-light">
                          Indian users can login via Phone number
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="ms-auto me-3">
                        {/* <Image src={outlineEmail} width={32} /> */}
                        <MdOutlineEmail className="text-muted " size={24} />
                      </div>
                      <div className="me-auto">
                        <p
                          className="text-sm text-light fw-bold"
                          style={{ fontWeight: "500" }}
                        >
                          Login with email
                        </p>
                        <p className="text-xs text-light">
                          International users can login via email
                        </p>
                      </div>
                    </>
                  )}
                </Stack>
              </div>
            ) : (
              <div className="w-md-96 w-full mx-auto">
                <Form>
                  <OTPInput
                    containerStyle="d-flex gap-3 mb-12 justify-content-center"
                    inputStyle="form-control form-control-dark form-control-lg text-40 font-bold w-12 px-4 otp-input control"
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                    value={authData?.enter_otp}
                    onChange={handleOtpChange}
                    shouldAutoFocus
                    inputType="number"
                  />
                  <Button
                    variant=""
                    className="bg-Golden-gradient text-dark w-full"
                    onClick={handleOtpVerifySubmit}
                    disabled={authData?.enter_otp.length < 6}
                  >
                    Verify OTP
                  </Button>
                  <p className="pt-8 text-center">
                    Didn’t receive OTP?{" "}
                    <Link
                      className="text-secondary font-bold ms-1 text-gradient"
                      onClick={handleOtpResendOtp}
                    >
                      Resend
                    </Link>
                  </p>
                </Form>
              </div>
            )}

            <div className="hstack justify-content-center mt-auto mb-20">
              <p>Powered by</p>
              <Image src={TapgproFullLogo} width={62} />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CustomerLogin;
